import { ref, computed } from 'vue'
import { defineStore, createPinia, setActivePinia } from 'pinia'

setActivePinia(createPinia())

// export const useMeStore = defineStore({
//   // unique id of the store across your application
//   id: 'me',
//   state: () => ({
//     token: ''
//   }),
//   getters: {
//     isLoggedIn: (state) => state.token.length > 0
//   }
// })

export const useMeStore = defineStore('me', () => {
  // unique id of the store across your application
  const token = ref('')
  const isLoggedIn = computed(() => token.value.length > 0)

  return { token, isLoggedIn }
})
