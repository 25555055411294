<template>
  <form class="dibooq-signin">
    <div class="input-block">
      <input
        v-model="user"
        autocomplete="user-email"
        class="user-input text-input field"
        required
        minlength="4"
        maxlength="50"
      />
    </div>
    <div class="input-block">
      <input
        v-model="password"
        type="password"
        autocomplete="password"
        class="password-input text-input field"
        required
        minlength="4"
        maxlength="50"
      />
    </div>
    <button class="signin-button" @click="signIn">Sign In</button>
  </form>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

import { Me } from '@BC/Me'

import { useMeStore } from '@/stores/me'

const meStore = useMeStore()

const emit = defineEmits<{
  'wc-signin': [{ success: boolean }] // named tuple syntax
}>()

const user = ref('')
const password = ref('')

const signIn = () => {
  console.log('signin clicked inside!')
  emit('wc-signin', { success: true })
  Me.logIn('token')
  meStore.token = 'token'
}
</script>

<style lang="scss" scoped>
.dibooq-signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  background-color: darkgoldenrod;

  .input-block {
    margin: 0.5rem 0;
  }
}
</style>
