
export interface Property {
  id: string
  name: string
}

export class RealEstate {
  static _allRows = <Property[]>[
    { id: '1', name: 'Finca Palma' },
    { id: '2', name: 'Finca Berlin' },
    { id: '3', name: 'Finca Potsdam' },
    { id: '4', name: 'Finca Nowhere' },
    { id: '5', name: 'Finca Eppendorf' },
    { id: '7', name: 'Finca Bramfeld' },
  ]

  static byId(id = '') {
    return id.length ? RealEstate._allRows.find(el => el.id === id) : RealEstate._allRows
  }

}

