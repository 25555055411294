<template>
  <div v-if="activeProperty" class="property-details">
    <div class="details property-card">This is {{ activeProperty.name ?? 'none' }}</div>
    <button @click="emit('close')">Back</button>
  </div>
  <div v-else class="property-details">
    <div class="details property-card">No property selected</div>
    <button @click="emit('close')">Back</button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, reactive } from 'vue'
import { RealEstate, type Property } from '@BC/RealEstate'

const props = withDefaults(
  defineProps<{
    id?: string
  }>(),
  {
    id: '',
  },
)

const emit = defineEmits<{
  close: [] // named tuple syntax
}>()

const activeProperty = computed(() => <Property | undefined>RealEstate.byId(props.id))
</script>

<style lang="scss" scoped>
.property-card {
  cursor: pointer;
  padding: 10px;
  border: 1px solid green;
  background-color: burlywood;
  margin: 10px;
}
</style>
