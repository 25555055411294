<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <h2>Localisation:</h2>
  <p>{{ `${t('hello')} ${t('world')} (${t('language')})` }}</p>
</template>
