<template>
  <div class="dibooq-wc dibooq-properties">
    <div>DiBooq Properties wc</div>
    <div v-if="propertyId === ''" class="all-properties">
      <div
        v-for="(property, pIx) in properties"
        :key="pIx"
        class="property-card"
        @click="show(property?.id)"
      >
        {{ property?.name }}#{{ property?.id }}
      </div>
    </div>
    <dibooq-property v-if="propertyId" :id="propertyId" @close="propertyId = ''" />

    <div>
      Signin-state: {{ stateObject.signInStatus }} (works only in externally connected wc´s)
    </div>
    <!-- <div>computed from Me: {{ isLoggedIn }}</div> -->
    <!-- <button @click="syncToken">{{ `Token is ${me.token}` }}</button> -->
    <div>localMe.token: {{ localMe.token }}</div>
    <!-- <div>localToken: {{ localToken }}</div> -->
    <div>meStore.token: {{ meStore.token }}</div>
    <!-- <div>token.value: {{ token }}</div> -->
    <div>meStore.isLoggedIn: {{ meStore.isLoggedIn }}</div>
    <div>Selected language: {{ t('lang') }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, reactive } from 'vue'
import { Me, me } from '@BC/Me'

import { useMeStore } from '@/stores/me'

import { RealEstate, type Property } from '@BC/RealEstate'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const meStore = useMeStore()

export interface State {
  signInStatus: boolean
}

const props = withDefaults(
  defineProps<{
    state?: string
  }>(),
  {
    state: JSON.stringify({ signInStatus: false }),
  },
)

const propertyId = ref('')
const localMe = reactive(me)

// const token = ref(Me.getToken())
// const syncToken = () => {
//   token.value = Me.getToken()
//   console.log('syncToken', token.value, localMe)
// }

const properties = <Property[]>RealEstate.byId()

const show = (id: string) => (propertyId.value = id)

const stateObject = computed(() => JSON.parse(props.state))

watch(
  () => props.state,
  (current, prev) => {
    // console.log('state', JSON.parse(current), JSON.parse(prev))
    console.log(
      'watch state from',
      prev ? JSON.parse(prev) : '',
      'to',
      current ? JSON.parse(current) : '',
    )
  },
)
</script>

<style lang="scss">
@import '@/assets/main.css';
</style>

<style lang="scss" scoped>
.property-card {
  cursor: pointer;
  padding: 10px;
  border: 1px solid green;
  background-color: brown;
  margin: 10px;
}

h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
