// used to compile libs via "npm run build"
import './assets/main.css'
// import './assets/ionic.css'

// /* Core CSS required for Ionic components to work properly */
// import '@ionic/vue/css/core.css'

// /* Basic CSS for apps built with Ionic */
// import '@ionic/vue/css/normalize.css'
// import '@ionic/vue/css/structure.css'
// import '@ionic/vue/css/typography.css'

// /* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css'
// import '@ionic/vue/css/float-elements.css'
// import '@ionic/vue/css/text-alignment.css'
// import '@ionic/vue/css/text-transformation.css'
// import '@ionic/vue/css/flex-utils.css'
// import '@ionic/vue/css/display.css'

import { defineCustomElement } from 'vue'

import DibooqPropertiesComponent from './components/DibooqProperties.ce.vue'
import DibooqPropertyComponent from './components/DiBooqProperty.ce.vue'
import DibooqSigninComponent from './components/DibooqSignin.ce.vue'

import I18nHost from './components/I18nHost.ce.vue'
import HelloI18n from './components/HelloI18n.ce.vue'
import HelloBlock from './components/HelloBlock.ce.vue'

const DibooqProperties = defineCustomElement(DibooqPropertiesComponent)
const DibooqProperty = defineCustomElement(DibooqPropertyComponent)
const DibooqSignin = defineCustomElement(DibooqSigninComponent)
customElements.define('dibooq-properties', DibooqProperties)
customElements.define('dibooq-property', DibooqProperty)
customElements.define('dibooq-signin', DibooqSignin)

const I18nHostElement = defineCustomElement(I18nHost)
const HelloI18nElement = defineCustomElement(HelloI18n)
const HelloBlockElement = defineCustomElement(HelloBlock)
customElements.define('i18n-host', I18nHostElement)
customElements.define('hello-i18n', HelloI18nElement)
customElements.define('hello-block', HelloBlockElement)

// customElements.define('dibooq-ionic', DibooqIonic)

// export { DibooqProperties, DibooqSignin, DibooqIonic }
// export function register() {
//  ...
// }


