<script lang="ts">
import { defineComponent, provide, watchEffect, nextTick } from 'vue'
import { createI18n, I18nInjectionKey } from 'vue-i18n'

import en from '@/i18n/en.json' // default

/**
 * Define the web components that host the i18n instance.
 *
 * Because the web components environment isn't hosted in a Vue apps by `createApp`, but is provided by itself.
 * The i18n instance created by `createI18n` will be installed with `app.use` in Vue apps,
 * so that you can use i18n features with `useI18n` in Vue components.
 * In order to use `useI18n` in web components, you need to have web components hosted as root to use it.
 */

/**
 * create an i18n instance to host for other web components
 *
 * NOTE:
 *  In web components only supports the composition API.
 *  It will not work in legacy API mode.
 */

type LanguageFile = Record<string, string>
type Messages = Record<string, LanguageFile>
const messages = {
  en, // default
} as Messages

// ;async () => {
//   await loadLocaleMessages('en')
// }

const i18n = createI18n<false>({
  legacy: false, // must set to `false`
  locale: 'en',
  messages,
})

export const loadLocaleMessages = async function (locale: string) {
  if (!messages[locale]) {
    // load translation only once
    const data = import.meta.glob('/src/i18n/*.json')

    // const suffix = ['app', 'bi'].includes(process.env.BUILD_MODE ?? '')
    //   ? '-stripped'
    //   : ''
    const suffix = ''
    const newMessages = (await data[`/src/i18n/${locale}${suffix}.json`]()) as Record<
      'default',
      LanguageFile
    >
    messages[locale] = newMessages.default as LanguageFile
  }

  i18n.global.locale.value = locale

  // i18n.global.setLocaleMessage(locale, messages.default)
  // set locale and locale message
  // messages.default && i18n.global.setLocaleMessage(locale, messages.default)

  /* load localized country names from here:
    https://github.com/stefangabos/world_countries/tree/master/data/countries
    countries_xx.json: short version
    world_xx.json: full version
  */
  // const countryData = await import(`../data/countries/world_${locale}.json`)
  // const country = countryData?.default?.length
  //   ? countryData.default
  //   : (await import('../data/countries/world_en.json')).default

  // useEnvStore().constantValues.localCountryNames = country

  await nextTick()
  console.log(`'I18nHost: new locale ${locale}`)
  return
}

export default defineComponent({
  props: {
    locale: {
      type: String,
      default: document.documentElement.lang,
    },
  },
  setup(props) {
    /**
     * provide i18n instance with `I18nInjectionKey` for other web components
     */
    provide(I18nInjectionKey, i18n)
    console.log(`'I18nHost: default locale ${props.locale}`)

    watchEffect(() => {
      loadLocaleMessages(props.locale)
    })
    return {}
  },
})
</script>

<template>
  <slot />
</template>
