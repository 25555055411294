<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
})
</script>

<template>
  <h2>Block</h2>
  <p>{{ t('world') }}</p>
</template>

<i18n>
{
  "en": {
    "world": "The world!"
  },
  "de": {
    "world": "Die Welt!"
  }
}
</i18n>
