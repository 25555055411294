export const me = { token: '' }

export class Me { // extends BusinessObject<GetProfileQuery> {
  // constructor(instanceData: GetProfileQuery, lifecycleStatus = 'R') {
  //   super(instanceData, lifecycleStatus)
  // }
  static readonly objectCacheStoreName = 'appStatus'

  private static token = ''

  static loggedIn() {
    console.log(`Me.loggedIn: ${Me.token}`)
    return Me.token.length > 0
  }

  static logIn(token: string) {
    Me.token = token
    me.token = token
    console.log(`Me.token: ${Me.token}`)
  }

  static logOut() {
    Me.token = ''
    me.token = ''
    console.log(`Me.token: ${Me.token}`)
  }

  static getToken() {
    return Me.token
  }
}

